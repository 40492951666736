import * as yup from 'yup';
import { description, itemName, notes, projectPrice, sku } from './baseSchema';

const taskCommonSchema = yup.object().shape({
  name: itemName,
  sku,
  cost: projectPrice,
  price: projectPrice,
  description,
  subtasks: yup.array().of(
    yup.object().shape({
      order: yup.number().required('Required!').typeError('Required!'),
      templateHours: yup
        .number()
        .min(0.01, 'Should be greater than 0')
        .max(999.99, 'Should be less than 999.99')
        .required('Hours is required!')
        .typeError('Hours is required!'),
      name: yup.string().required('Task Name is required!'),
      notes,
    })
  ),
});

export default taskCommonSchema;

export enum AdministrationsTabsEnum {
  ACTIVE_USERS = 'Active Users',
  INVITED_USERS = 'Invited',
}

export enum InvitationStatusEnum {
  EXPIRED = 'Expired',
  INVITED = 'Invited',
}

export enum PerformerTypeEnum {
  user = 'user',
  resource = 'resource',
  system = 'system',
  subcontractor = 'subcontractor',
}

export enum RepeatEnum {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum SortTasksByEnum {
  FromCompletedToNotStarted = 'from_completed_to_not_started',
  FromNotStartedToCompleted = 'from_not_started_to_completed',
}

export enum StateEnum {
  ToDo = 'To do',
  InProgress = 'In progress',
  Requested = 'Requested',
  Delivered = 'Delivered',
  RequestedForTakeOut = 'Requested for take out',
  Done = 'Done',
}

export enum TypeEnum {
  Common = 'common',
  Container = 'container',
}

export enum InboundModalTypesEnum {
  WORK_ORDERS = 'workOrders',
  DIVIDE_TASKS = 'divideTasks',
  CREATE = 'create',
  DETAILS = 'details',
}

export enum ResponseStatusEnum {
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export enum TaskTypeEnum {
  Common = 'common',
  Container = 'container',
}

export enum ContainerTaskStatusesEnum {
  TO_DO = 'To do',
  DONE = 'Done',
  DELIVERED = 'Delivered',
  REQUESTED = 'Requested',
  REQUESTED_FOR_TAKE_OUT = 'Requested for take out',
}

export enum CreateFormTypesEnum {
  GRADE = 'grade',
  SIZE = 'size',
  CONDITION = 'condition',
  COMPANY = 'company',
  COLOR = 'color',
}

export enum TableHeaderColumnsEnum {
  Data = 'Date',
  Invoice = 'Order #',
  OwnerEmail = 'Owner Email',
  Client = 'Client',
  Company = 'Company',
}

export enum ProjectStatusesEnum {
  DONE = 'Done',
  DELETED = 'Deleted',
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  WAITING_FOR_CTNR = 'Waiting for CTNR',
  WAITING_FOR_MATERIALS = 'Waiting for Materials',
  WAITING_FOR_SALES_RE_WORK = 'Waiting for Sales Re-Work',
}

export enum MessageType {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
}

export enum SettingsMenuLabelsEnum {
  Profile = 'Profile',
  Company = 'Company',
  Admin = 'Admin',
  ProjectSettings = 'Project Settings',
  DepotSettings = 'Depot Settings',
  Timesheets = 'Timesheets',
  Reports = 'Reports',
  FeaturesAndIntegrations = 'Features & Іntegrations',
}

export enum ProfileTabsEnum {
  MainInfo = 'Main Info',
  Password = 'Password',
}

export enum ProjectSettingsTabsEnum {
  TaskTypes = 'Task Types',
  RecurringTasks = 'Recurring Tasks',
  Items = 'Items',
  ProjectSettings = 'Project Settings',
}

export enum ModulesTabsEnum {
  Features = 'Features',
  Integrations = 'Integrations',
}

export enum AdminTabsEnum {
  Admins = 'Admins',
  Resources = 'Resources',
  Groups = 'Groups',
  EmailTemplates = 'Email Templates',
}

export enum DepotTabsEnum {
  Colors = 'Colors',
  Containers = 'Containers',
  Sizes = 'Sizes',
  Conditions = 'Conditions',
  Grades = 'Grades',
  TruckingCompanies = 'Trucking Companies',
}

export enum ProjectViewMode {
  Tasks = 'tasks',
  Items = 'items',
}

export enum CloneType {
  Project = 'project',
  Template = 'template',
}

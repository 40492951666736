import { FC } from 'react';
import { TaskModeItem } from './elements/TaskModeItem';
import * as Styled from './styles';
import { IProjectTaskDto, SubtaskModeList, TaskInfoProps } from 'types';

interface TaskModeProps extends TaskInfoProps {
  tasksList: IProjectTaskDto[];
  projectId: string;
  tasksGroupBySubtask: SubtaskModeList[];
  isProjectEditing?: boolean;
}

export const TaskMode: FC<TaskModeProps> = ({
  tasksList,
  projectId,
  tasksGroupBySubtask,
  isProjectEditing,
  ...rest
}) => (
  <Styled.List
    dataSource={tasksGroupBySubtask}
    rowKey={(item: SubtaskModeList) => item.subtaskName}
    renderItem={(subtask: SubtaskModeList) => (
      <TaskModeItem
        projectId={projectId}
        tasksList={tasksList}
        subtask={subtask}
        isProjectEditing={isProjectEditing}
        {...rest}
      />
    )}
  />
);

import * as yup from 'yup';
import {
  description,
  itemName,
  locationId,
  notes,
  plannedHours,
  projectPrice,
  sku,
} from './baseSchema';

const projectSchema = yup.object().shape({
  invoice: yup.string().required('Required!'),
  locationId,
  customer: yup
    .string()
    .trim()
    .max(30, 'Customer Name must be at most 30 characters')
    .required('Required!'),
  account: yup
    .string()
    .trim()
    .max(100, 'Account Name must be at most 100 characters')
    .required('Required!'),
  dueDate: yup
    .date()
    .required('Required!')
    .when(
      'startDate',
      (startDate, schema) =>
        startDate && schema.min(startDate, 'Must be > than start date!')
    ),
  notes,
  container: yup
    .object()
    .shape({
      name: itemName,
      sku,
      description,
      condition: yup.string().required('Condition is required!'),
      size: yup.string().required('Size is required!'),
      grade: yup.string().required('Grade is required!'),
      color: yup.string().required('Color is required!'),
      subtasks: yup.array().of(
        yup.object().shape({
          order: yup
            .number()
            .required('Required!')
            .typeError('Hours is required!'),
          plannedHours,
          name: yup.string().required('Task Name is required!'),
          notes,
          checklist: yup.array().of(
            yup.object().shape({
              _id: yup.string(),
              title: yup.string(),
              isCompleted: yup.boolean(),
            })
          ),
        })
      ),
    })
    .default(undefined),
  tasks: yup.array().of(
    yup.object().shape({
      name: itemName,
      sku,
      cost: projectPrice,
      price: projectPrice,
      description,
      subtasks: yup.array().of(
        yup.object().shape({
          order: yup
            .number()
            .required('Required!')
            .typeError('Hours is required!'),
          plannedHours,
          name: yup.string().required('Task Name is required!'),
          notes,
          checklist: yup.array().of(
            yup.object().shape({
              _id: yup.string(),
              title: yup.string(),
              isCompleted: yup.boolean(),
            })
          ),
        })
      ),
    })
  ),
});

export default projectSchema;

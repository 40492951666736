import { useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  containerOptionsSelector,
  isContainerOptionsLoadingSelector,
} from 'store/admin';
// redux
import {
  currentLocation,
  currentLocationSelector,
  isAdminSelector,
  locationTimezone,
} from 'store/auth';
import { companySelector } from 'store/company';
import {
  containerSkusFilter,
  gantt,
  ganttDates,
  ganttDatesSelector,
  projects,
  projectsSortBy,
  removeProjects,
  screenDistributionSelector,
} from 'store/dashboard';
import { workOrdersInfo } from 'store/inbound';
import { getInventory } from 'store/inventory';
import { globalSearch, locationsSelector } from 'store/predefined';
import { reportsDatesSelector, setReportsDates } from 'store/reports';
import { ROUTES, location } from 'store/router';
import {
  setTimesheetsDates as setTimesheetsDatesAction,
  timesheets,
  timesheetsDatesSelector,
} from 'store/timesheets';
import {
  getAvatarProps,
  getSizesOptions,
  getSkuOptions,
  getStartEndWeek,
  hasLocation,
} from 'utils/helpers';
import Search from '../Search';
// components
import { SortBySelect } from 'components/common';
// hooks
import { useActions } from 'hooks';
// utils
import { sortTypes } from 'lib/constants/sortTypes';
import { Separator } from 'lib/icons';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import storageManager from 'utils/storageManager';
import { getNewTimezoneTime } from 'utils/timezone';
// icons
import { Avatar, Col } from 'antd';
import { StyledSelect } from './styles';
import { ScreenDistributionTypesEnum } from 'types';

interface ILeftPanelProps {
  isDepotManager: boolean;
}

const LeftPanel = ({ isDepotManager }: ILeftPanelProps): JSX.Element => {
  const setSearch = useActions(globalSearch);
  const setReportDates = useActions(setReportsDates);
  const setTimesheetsDates = useActions(setTimesheetsDatesAction);
  const setLocationTimezone = useActions(locationTimezone);
  const gantDates = useSelector(ganttDatesSelector, isEqual);
  const reportDates = useSelector(reportsDatesSelector, isEqual);
  const timesheetsDates = useSelector(timesheetsDatesSelector, isEqual);
  const containerOptions = useSelector(containerOptionsSelector);
  const currentLocationId = useSelector(currentLocationSelector);
  const company = useSelector(companySelector);
  const isAdmin = useSelector(isAdminSelector);
  const isContainerOptionsLoading = useSelector(
    isContainerOptionsLoadingSelector
  );
  const locations = useSelector(locationsSelector);
  const routerLocation = useSelector(location);
  const screenDistribution = useSelector(screenDistributionSelector);
  const [
    changeLocation,
    changeProjectsSortBy,
    changeContainerSkusFilter,
    getWorkOrdersInfo,
    getProjects,
    clearProjectsList,
    getGantt,
    setGanttDates,
    getInventoryList,
    getTimesheets,
  ] = useActions([
    currentLocation,
    projectsSortBy,
    containerSkusFilter,
    workOrdersInfo,
    projects,
    removeProjects,
    gantt,
    ganttDates,
    getInventory,
    timesheets,
  ]);

  const {
    fullName: companyName,
    color,
    background,
  } = getAvatarProps(company?.name);
  const isFullProjects =
    screenDistribution === ScreenDistributionTypesEnum.FULL_PROJECTS;

  const handleSortTypes = useCallback(
    value => {
      changeProjectsSortBy(value);
      getProjects({
        isLocationChange: true,
        getAll: value === 'weight' && isFullProjects,
      });
    },
    [changeProjectsSortBy, getProjects, isFullProjects, routerLocation.pathname]
  );

  // const handleSizesFilter = useCallback(
  //   (sizes: string[]) => {
  //     changeContainerSizesFilter(sizes);
  //     getProjects({ isLocationChange: true, isFullProjects });
  //   },
  //   [getProjects, routerLocation.pathname]
  // );

  const handleSkuFilter = useCallback(
    (skus: string[]) => {
      changeContainerSkusFilter(skus);
      getProjects({ isLocationChange: true, noLoader: true, isFullProjects });
    },
    [getProjects, routerLocation.pathname]
  );

  const updateTimezone = useCallback(
    newLocation => {
      const timezone = company?.locations?.find(
        location => location._id === newLocation
      )?.timezone;
      const utcOffset = momentTimezone.tz(timezone).utcOffset();

      const gantDatesWithTimezone = getNewTimezoneTime(
        gantDates?.startDate,
        gantDates?.endDate,
        utcOffset,
        timezone
      );

      setGanttDates({
        ...gantDatesWithTimezone,
        weekStartDay: company?.weekStartDay,
      });

      if (!reportDates) {
        const { startDate, endDate } = getStartEndWeek(
          company?.weekStartDay,
          momentTimezone(new Date()).tz(timezone)
        );
        setReportDates({ startDate, endDate });
      } else {
        const reportDatesWithTimezone = getNewTimezoneTime(
          reportDates?.startDate,
          reportDates?.endDate,
          utcOffset,
          timezone
        );

        setReportDates(reportDatesWithTimezone);
      }

      if (!timesheetsDates) {
        const { startDate, endDate } = getStartEndWeek(
          company?.weekStartDay,
          moment()
        );

        setTimesheetsDates({ startDate, endDate });
      } else {
        const timesheetsDatesWithTimezone = getNewTimezoneTime(
          timesheetsDates?.startDate,
          timesheetsDates?.endDate,
          utcOffset,
          timezone
        );

        setTimesheetsDates(timesheetsDatesWithTimezone);
      }

      setLocationTimezone(timezone);
      momentTimezone.tz.setDefault(timezone);
      storageManager.setTimezone(timezone);
      moment().utcOffset(utcOffset);
    },
    [
      company,
      gantDates,
      reportDates,
      timesheetsDates,
      setGanttDates,
      setReportDates,
      setTimesheetsDates,
      setLocationTimezone,
    ]
  );

  const handleChangeLocation = useCallback(
    value => {
      changeLocation(value);
      setSearch('');
      storageManager.setLocation(value);
      if (company?.isInboundWOEnabled) getWorkOrdersInfo();
      updateTimezone(value);
      clearProjectsList();
      if (routerLocation.pathname === '/dashboard') {
        getProjects({ isLocationChange: true });
        getGantt();
      }

      if (routerLocation.pathname === ROUTES.SETTINGS_TIME_SHEETS) {
        getTimesheets();
      }
      if (routerLocation.pathname === ROUTES.INVENTORY) getInventoryList();
    },
    [
      changeLocation,
      getProjects,
      getGantt,
      getInventoryList,
      getTimesheets,
      getWorkOrdersInfo,
      routerLocation.pathname,
      company,
      clearProjectsList,
      setSearch,
      updateTimezone,
    ]
  );

  const localStorageLocationId = storageManager.getLocation();
  const stateLocationId = currentLocationId || undefined;

  const locationId = hasLocation(locations, localStorageLocationId)
    ? localStorageLocationId
    : stateLocationId;

  const logoLinkTo = isDepotManager ? '/setting/profile' : '/dashboard';

  return (
    <Col className="left-col">
      <Link to={logoLinkTo}>
        <Avatar
          shape="square"
          size={33}
          className="avatar"
          src={company?.logoUrl}
          style={{ color, background }}
        >
          {companyName}
        </Avatar>
      </Link>
      {!isDepotManager && (
        <>
          <Separator className="separator" />
          <SortBySelect
            options={locations}
            showSearch={false}
            onChange={handleChangeLocation}
            placeholder="Select location"
            autoClearSearchValue={true}
            value={locationId}
          />
          <SortBySelect
            withPrefix
            showSearch={false}
            options={sortTypes}
            onChange={handleSortTypes}
            defaultValue="dueDate"
            width="100%"
          />
          <Search />
          {/* {isAdmin && company.isContainersEnabled && (
            <ContainerSizesSelect
              loading={isContainerOptionsLoading}
              placement="bottomRight"
              placeholder='Container search'
              showArrow
              isMultiple
              showSearch
              width="200px"
              maxTagCount="responsive"
              options={getSizesOptions(companyOptions.sizes)}
              getPopupContainer={trigger => trigger.parentNode}
              onChange={handleSizesFilter}
            />
          )} */}
          {isAdmin && company.isContainersEnabled && (
            <StyledSelect
              loading={isContainerOptionsLoading}
              placement="bottomRight"
              placeholder="Container sku search"
              showArrow
              isMultiple
              showSearch
              width="200px"
              maxTagCount="responsive"
              options={getSkuOptions(containerOptions.skus)}
              getPopupContainer={trigger => trigger.parentNode}
              onChange={handleSkuFilter}
            />
          )}
        </>
      )}
    </Col>
  );
};

export default LeftPanel;

import React, { memo, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Error, ISelectInputProps, IconButton } from 'components/common';
import { PencilWithLine } from 'lib/icons';
import { EmailTemplateModal } from 'pages/Admin/EmailTemplates/EmailTemplateModal';
import { ButtonWrap, Wrap } from './styles';
import { AutoCompleteStyled } from 'components/common/Autocomplete/styles';
import { IEmailTemplate } from 'types';

interface ITemplateSelectProps {
  onTemplateEdit?: (editedItem: IEmailTemplate) => void;
  onTemplateChange?: () => void;
  getPopupContainer?: (trigger: React.ReactNode) => void;
  filterOption?: Function | boolean;
  editItem?: IEmailTemplate;
  error?: string;
}

const TemplateSelectComponent = ({
  onTemplateEdit,
  editItem,
  filterOption,
  error,
  ...props
}: ISelectInputProps & ITemplateSelectProps) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleOpenModal = useCallback(() => setIsModalOpened(true), []);
  const handleCloseModal = useCallback(
    newValue => {
      if (newValue) onTemplateEdit(newValue);
      setIsModalOpened(false);
    },
    [onTemplateEdit]
  );

  return (
    <Wrap>
      <div>
        <AutoCompleteStyled {...props} filterOption={filterOption} />
        {editItem ? (
          <ButtonWrap>
            <IconButton
              onClick={handleOpenModal}
              icon={<PencilWithLine />}
              size={10}
              background={'white'}
            />
          </ButtonWrap>
        ) : null}
      </div>
      <Error>{error}</Error>
      <EmailTemplateModal
        isOpen={isModalOpened}
        isTemplateModal
        item={editItem}
        onCloseModal={handleCloseModal}
      />
    </Wrap>
  );
};

export const TemplateSelect = memo(TemplateSelectComponent, isEqual);

import { Dispatch, SetStateAction, memo, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
// components
import SubtasksInfo from './SubtasksInfo';
import TaskEditInfo from './TaskEditInfo';
import {
  HookFormRecord,
  IProject,
  IProjectTaskDto,
  ISubtaskTypeDto,
  ISubtasksErrors,
} from 'types';

interface ITaskFormProps {
  item?: IProjectTaskDto;
  subtasksInWrap?: boolean;
  isTemplate?: boolean;
  subtaskTypes?: ISubtaskTypeDto[];
  name?: string;
  isEdit?: boolean;
  appendTask?: (task: IProjectTaskDto[]) => void;
  isInProject?: boolean;
  errors?: ISubtasksErrors;
  index?: number;
  order?: number;
  isLocationChanged?: boolean;
  isSingle?: boolean;
  setRelated?: (tasks: IProjectTaskDto[]) => void;
  projectId: string;
  project?: IProject;
  appendContainer?: (container: IProjectTaskDto) => void;
  setRemoveChatsIds?: Dispatch<SetStateAction<string[]>>;
}

const TaskForm = ({
  setRemoveChatsIds,
  isEdit,
  item,
  subtasksInWrap,
  isTemplate,
  subtaskTypes,
  name = '',
  errors,
  isInProject,
  appendTask,
  order,
  setRelated,
  isSingle,
  index,
  projectId,
  project,
  appendContainer,
}: ITaskFormProps) => {
  const { setValue, watch, register, clearErrors, control } = useFormContext();

  const subtasks = useFieldArray({
    control,
    name: `${name}subtasks` as HookFormRecord,
  });

  const itemWatch = isSingle
    ? watch()
    : watch(`tasks[${index}]` as HookFormRecord);

  useEffect(() => {
    if (!item?.subtasks) return;
    subtasks.replace(item.subtasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  useEffect(() => {
    if (isEdit && item) {
      register(`${name}type` as HookFormRecord);
      register(`${name}_id` as HookFormRecord);
      register(`${name}createdAt` as HookFormRecord);
      register(`${name}updatedAt` as HookFormRecord);
      register(`${name}order` as HookFormRecord);

      setValue(`${name}type` as HookFormRecord, item.type);
      setValue(`${name}_id` as HookFormRecord, item._id);
      setValue(`${name}createdAt` as HookFormRecord, item.createdAt);
      setValue(`${name}updatedAt` as HookFormRecord, item.updatedAt);
      setValue(`${name}order` as HookFormRecord, item.order);
    }

    if (item?.taskNotificationId) {
      register(`${name}taskNotificationId` as HookFormRecord);
      setValue(
        `${name}taskNotificationId` as HookFormRecord,
        item?.taskNotificationId as HookFormRecord
      );
    }
    // eslint-disable-next-line
  }, [isEdit, item]);

  return (
    <>
      <TaskEditInfo
        item={item as any}
        itemWatch={itemWatch}
        name={name}
        errors={errors}
        subtasks={subtasks}
        order={order}
        isTemplate={isTemplate}
        appendTask={appendTask as any}
        setRelated={setRelated as any}
        appendContainer={appendContainer as any}
      />
      <SubtasksInfo
        isEdit={isEdit}
        subtasksInWrap={subtasksInWrap}
        isTemplate={isTemplate}
        subtaskTypes={subtaskTypes}
        name={name}
        errors={errors}
        subtasks={subtasks}
        isInProject={isInProject}
        projectId={projectId}
        project={project}
        setRemoveChatsIds={setRemoveChatsIds}
      />
    </>
  );
};

TaskForm.defaultProps = {
  subtasksInWrap: false,
  isTemplate: false,
  isEdit: false,
  isParentOpen: false,
};

export default memo(TaskForm);

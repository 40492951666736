import { lazy } from 'react';
// utils
import {
  settingsRoutesAdmin,
  settingsRoutesDepotManager,
  settingsRoutesManager,
  settingsRoutesManagerAndDepotManager,
  settingsRoutesSalesManager,
  settingsRoutesScheduler,
} from './routesPath';
import { profileWrapper } from './wrappers';
// components
import {
  adminSiderItems,
  depotManagerSiderItems,
  managerSiderItems,
  salesManagerSiderItems,
  schedulerSiderItems,
} from 'lib/constants/menuItems';

// list of routes related to user role
const Dashboard = lazy(async () => import('pages/Dashboard'));
const Inventory = lazy(async () => import('pages/Inventory'));
const CustomerView = lazy(async () => import('pages/CustomerView'));
const Subcontractors = lazy(async () => import('pages/Subcontractors'));

export const routes = {
  admin: () => [
    { exact: true, path: '/dashboard', component: Dashboard },
    { exact: true, path: '/inventory', component: Inventory },
    {
      exact: true,
      path: '/projects/:id/sharing/:jwt',
      component: CustomerView,
    },
    {
      exact: true,
      path: '/subtasks/:id/sharing/:jwt',
      component: Subcontractors,
    },
    {
      exact: false,
      path: '/settings',
      redirect: '/settings/profile',
      wrapper: children => profileWrapper(children, adminSiderItems),
      routes: settingsRoutesAdmin,
    },
  ],
  superAdmin: () => [
    { exact: true, path: '/dashboard', component: Dashboard },
    { exact: true, path: '/inventory', component: Inventory },
    {
      exact: true,
      path: '/projects/:id/sharing/:jwt',
      component: CustomerView,
    },
    {
      exact: true,
      path: '/subtasks/:id/sharing/:jwt',
      component: Subcontractors,
    },
    {
      exact: false,
      path: '/settings',
      redirect: '/settings/profile',
      wrapper: children => profileWrapper(children, adminSiderItems),
      routes: settingsRoutesAdmin,
    },
  ],
  manager: () => [
    { exact: true, path: '/dashboard', component: Dashboard },
    { exact: true, path: '/inventory', component: Inventory },
    {
      exact: true,
      path: '/projects/:id/sharing/:jwt',
      component: CustomerView,
    },
    {
      exact: true,
      path: '/subtasks/:id/sharing/:jwt',
      component: Subcontractors,
    },
    {
      exact: false,
      path: '/settings',
      redirect: '/settings/profile',
      wrapper: children => profileWrapper(children, managerSiderItems),
      routes: settingsRoutesManager,
    },
  ],
  depotManager: () => [
    {
      exact: true,
      path: '/projects/:id/sharing/:jwt',
      component: CustomerView,
    },
    {
      exact: true,
      path: '/subtasks/:id/sharing/:jwt',
      component: Subcontractors,
    },
    {
      exact: false,
      path: '/settings',
      redirect: '/settings/profile',
      wrapper: children => profileWrapper(children, depotManagerSiderItems),
      routes: settingsRoutesDepotManager,
    },
  ],
  managerAndDepotManager: () => [
    { exact: true, path: '/inventory', component: Inventory },
    { exact: true, path: '/dashboard', component: Dashboard },
    {
      exact: true,
      path: '/projects/:id/sharing/:jwt',
      component: CustomerView,
    },
    {
      exact: true,
      path: '/subtasks/:id/sharing/:jwt',
      component: Subcontractors,
    },
    {
      exact: false,
      path: '/settings',
      redirect: '/settings/profile',
      wrapper: children => profileWrapper(children, adminSiderItems),
      routes: settingsRoutesManagerAndDepotManager,
    },
  ],
  scheduler: () => [
    { exact: true, path: '/inventory', component: Inventory },
    { exact: true, path: '/dashboard', component: Dashboard },
    {
      exact: true,
      path: '/projects/:id/sharing/:jwt',
      component: CustomerView,
    },
    {
      exact: true,
      path: '/projects',
      component: CustomerView,
    },
    {
      exact: true,
      path: '/subtasks/:id/sharing/:jwt',
      component: Subcontractors,
    },
    {
      exact: false,
      path: '/settings',
      redirect: '/settings/profile',
      wrapper: children => profileWrapper(children, schedulerSiderItems),
      routes: settingsRoutesScheduler,
    },
  ],
  salesManager: () => [
    {
      exact: true,
      path: '/projects/:id/sharing/:jwt',
      component: CustomerView,
    },
    { exact: true, path: '/dashboard', component: Dashboard },
    {
      exact: true,
      path: '/subtasks/:id/sharing/:jwt',
      component: Subcontractors,
    },
    {
      exact: false,
      path: '/settings',
      redirect: '/settings/profile',
      wrapper: children => profileWrapper(children, salesManagerSiderItems),
      routes: settingsRoutesSalesManager,
    },
  ],
};

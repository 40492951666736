import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import { ganttWeekViewSelector } from 'store/dashboard';
import {
  clearGanttStateAction,
  msInPixelSelector,
  setDropFromProjectAction,
  setMouseOffsetAction,
  setTaskIdAction,
} from 'store/gantt';
import { ROUTES, location } from 'store/router';
import { getHelpsData } from 'utils/helpersSubtasks';
import Preview from 'components/SubtaskViewComponents/Preview';
import { useActions } from 'hooks';
import {
  SUBTASK_STATUSES_INVERTED,
  SUBTASK_STATUSES_PROPERTIES,
} from 'lib/constants/subtasks';
import { NoResourse } from 'lib/icons';
import { Item } from './styles';
import { DragTypeEnum, ISubtaskDto, SubtaskStatusesEnum } from 'types';

export interface ITagProps {
  key?: string;
  subtask?: ISubtaskDto;
  toggleParent?: (visible: boolean) => void;
  notAvailableProject?: boolean;
  projectId?: string;
  color?: string;
  invoice?: string;
  taskId?: string;
  taskName?: string;
  taskOrder?: string | number;
}

const Tag = ({
  subtask,
  notAvailableProject,
  taskId,
  toggleParent,
  ...rest
}: ITagProps) => {
  const isWeek = useSelector(ganttWeekViewSelector);
  const msInPixel = useSelector(msInPixelSelector);
  const routerLocation = useSelector(location);

  const setTaskId = useActions(setTaskIdAction);
  const setDropFromProject = useActions(setDropFromProjectAction);
  const setMouseOffset = useActions(setMouseOffsetAction);
  const clearGanttState = useActions(clearGanttStateAction);

  const { title, width, item, mouseOffset } = getHelpsData(
    rest,
    subtask,
    isWeek,
    msInPixel
  );

  const isDashboard = routerLocation.pathname === ROUTES.DASHBOARD;

  const { status, resource, generatedApiKey, name, taskName } = subtask;

  const isStatusAllowedToDrag =
    status === SubtaskStatusesEnum.TO_DO ||
    status === SubtaskStatusesEnum.NOT_READY;

  const canDrag =
    isStatusAllowedToDrag &&
    !generatedApiKey &&
    isDashboard &&
    !notAvailableProject;

  // drag to asignee
  const [, drag, preview] = useDrag({
    type: DragTypeEnum.subtask,
    item: () => {
      setTaskId(taskId);
      setDropFromProject(true);
      setMouseOffset(mouseOffset);
      setTimeout(() => toggleParent(false), 0);
      return item;
    },
    canDrag: () => canDrag,

    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        setDropFromProject(false);
        clearGanttState(true);
      }
      toggleParent(true);
      setTaskId(undefined);
    },
  });

  const statusKey = SUBTASK_STATUSES_INVERTED[status];
  const assigneed = resource || generatedApiKey;
  const color = SUBTASK_STATUSES_PROPERTIES[statusKey].color;
  const backgroundColor = SUBTASK_STATUSES_PROPERTIES[statusKey].background;

  return (
    <>
      <Preview
        color={color}
        width={width}
        title={title}
        ref={preview}
        item={item}
      />
      <Item
        ref={drag}
        noAssignee={!assigneed}
        background={backgroundColor}
        textColor={color}
      >
        {!assigneed && <NoResourse color={color} />}
        {taskName ? taskName : name}
      </Item>
    </>
  );
};

export default Tag;

import theme from 'styles/theme';

export const PROJECTS_STATUSES = {
  DONE: 'Done',
  DELETED: 'Deleted',
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  WAITING_FOR_CTNR: 'Waiting for CTNR',
  WAITING_FOR_MATERIALS: 'Waiting for Materials',
  WAITING_FOR_SALES_RE_WORK: 'Waiting for Sales Re-Work',
};

export const PROJECTS_STATUSES_STYLES = {
  [PROJECTS_STATUSES.IN_PROGRESS]: {
    color: theme.colors.white,
    background: theme.colors.lightDeepBlue,
  },
  [PROJECTS_STATUSES.DONE]: {
    color: theme.colors.eucalyptus,
    background: theme.colors.lightGreen,
  },
  [PROJECTS_STATUSES.NOT_STARTED]: {
    color: theme.colors.textGreyDark,
    background: theme.colors.textGrey,
  },
  [PROJECTS_STATUSES.DELETED]: {
    color: theme.colors.danger,
    background: theme.colors.lightDanger,
  },
  [PROJECTS_STATUSES.WAITING_FOR_CTNR]: {
    color: theme.colors.orange,
    background: theme.colors.lightOrange,
  },
  [PROJECTS_STATUSES.WAITING_FOR_MATERIALS]: {
    color: theme.colors.purple,
    background: theme.colors.lightPurple,
  },
  [PROJECTS_STATUSES.WAITING_FOR_SALES_RE_WORK]: {
    color: theme.colors.pine,
    background: theme.colors.cruise,
  },
};

export enum PARTIAL_PROJECT_UPDATE_TYPES {
  DONE = 'Done',
  DELETE = 'Deleted',
  ARCHIVE = 'Archive',
  UNARCHIVE = 'Unarchive',
  RESTORE = 'Restore',
}

import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import RestorePassword from './Auth/RestorePassword/RestorePassword';
import Restore from './Restore/Restore';
import { isAuthorizedSelector, isDepotManagerSelector, isSalesManagerSelector } from 'store/auth';
import { companySelector } from 'store/company';
import { removeProjects } from 'store/dashboard';
import { isLoadingSelector } from 'store/loading';
// redux
import { location } from 'store/router';
import { isMobileDevice } from 'utils/helpers';
import EmailVerification from './Auth/EmailVerification/EmailVerification';
import ForgotPassword from './Auth/ForgotPassword/ForgotPassword';
import Register from './Auth/Register/Register';
import CustomerView from './CustomerView';
import Main from './Main';
import RegisterNewCompany from './RegisterNewCompany/RegisterNewCompany';
import { useActions } from 'hooks';
// components
import SignIn from 'pages/Auth/Login/Login';
import NavBar from 'pages/NavBar';
import Subcontractors from 'pages/Subcontractors';
import { message } from 'antd';
// styles
import LayoutStyled from 'pages/styles';

const App = () => {
  const pageIsLoading = useSelector(isLoadingSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const { pathname } = useSelector(location);
  const isSales = useSelector(isSalesManagerSelector);
  const isDepot = useSelector(isDepotManagerSelector);
  const company = useSelector(companySelector);
  const isMobile = useMemo(() => isMobileDevice.any(), []);
  const dragAndDropBackend = useMemo(
    () => (isMobile ? TouchBackend : HTML5Backend),
    [isMobile]
  );
  const dragAndDropOptions = useMemo(
    () => (isMobile ? { delayTouchStart: 80 } : {}),
    [isMobile]
  );
  const isSharing = pathname.includes('sharing');
  const isSettings = pathname.includes('settings');
  const deleteProjects = useActions(removeProjects);

  useEffect(() => {
    if (company?.faviconUrl) {
      const favicon = document.getElementById('favicon');
      (favicon as any).href = company.faviconUrl;
    }
  }, [company?.faviconUrl]);

  useEffect(() => {
    if (isSettings) deleteProjects();
  }, [isSettings, deleteProjects]);

  if (pageIsLoading) {
    return null;
  }

  message.config({
    duration: 2,
    maxCount: 3,
  });

  const isCompanyDeleted = company?.deleted;

  if (isCompanyDeleted && isAuthorized) {
    return (
      <Switch>
        <Route path="/restore" component={Restore} />
        {/* <Route path="/login" component={() => null} /> */}
        <Route path="/register-new-company" component={RegisterNewCompany} />
        <Redirect to="/restore" />;
      </Switch>
    );
  }

  return !isAuthorized ? (
    <Switch>
      <Route exact={true} path="/" component={SignIn} />
      <Route path="/login" component={() => null} />
      <Route path="/validate-invite" component={() => null} />
      <Route path="/register" component={Register} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/restore-password" component={RestorePassword} />
      <Route path="/email-verification" component={EmailVerification} />
      <Route path="/subtasks/:id/sharing/:jwt" component={Subcontractors} />
      <Route path="/projects/:id/sharing/:jwt" component={CustomerView} />
      <Redirect to="/" />
    </Switch>
  ) : (
    <LayoutStyled $sharing={isSharing} className="main">
      <DndProvider backend={dragAndDropBackend} options={dragAndDropOptions}>
        {!isSharing && (
          <NavBar
            isDepotManager={isDepot}
            isSalesManager={isSales}
          />
        )}
        <Main />
      </DndProvider>
    </LayoutStyled>
  );
};

export default Sentry.withProfiler(App);

import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as Auth from 'store/auth';
import * as Company from 'store/company';
import { actions } from 'store/loading';
import { deleteLocation } from 'store/predefined';
import { RESPONSE_ERRORS } from 'lib/constants';
import watchNavigate from 'sagas/navigator';
import { message } from 'utils/message';
import { ServerErrorsEnum } from 'types';

export default function* rootSaga() {
  yield put(actions.loading(true));

  const isSharingPage = location.pathname.includes('sharing');

  if (isSharingPage) {
    yield all([
      fork(watchNavigate),
      fork(watchFailed),
      fork(routinePromiseWatcherSaga),
    ]);
    yield put(actions.loading(false));
  } else {
    yield all([
      fork(watchNavigate),
      fork(watchFailed),
      fork(Auth.watchSignOutPage),
      fork(Auth.watchResendVerificationCode),
      fork(Auth.watchValidateInviteToken),
      fork(Auth.watchSignUpWithInvite),
      fork(Auth.watchSignIn),
      fork(Auth.watchRestoreCompany),
      fork(Auth.watchRegisterNewCompany),
      fork(Auth.watchSignInFailure),
      fork(Company.watchGetEmployeesValues),
      fork(Company.watchTransferCompany),
      fork(Company.watchRestoreCompany),
      fork(Company.watchRegisterNewCompany),
      fork(routinePromiseWatcherSaga),
      call(Auth.ensureSignIn),
    ]);
  }
}

function* watchFailed() {
  yield takeEvery(
    // @ts-ignore
    action =>
      action.payload?.data?.message !== RESPONSE_ERRORS.auth.server &&
      action.type.toLowerCase().includes('failure') &&
      action.type !== deleteLocation.FAILURE &&
      action.payload?.data?.message !== RESPONSE_ERRORS.email.server,
    ensureFailed
  );
}

export function* ensureFailed({ payload }) {
  try {
    const messageText = payload?.data?.message || payload?.data?.errorMessage;
    const errorCode = payload?.data?.errorCode;

    if (
      [
        ServerErrorsEnum.RESOURCE_ALREADY_HAS_SUBTASK_ASSIGNED_IN_DATE_RANGE,
        ServerErrorsEnum.RESOURCE_HAS_ACTIVE_SUBTASK,
        ServerErrorsEnum.CONTAINER_NUMBER_EXISTS,
        ServerErrorsEnum.INVALID_TASK_TYPE,
      ].includes(errorCode)
    ) {
      return;
    }

    let errors;

    if (
      payload?.status === 403 &&
      payload?.data.errorCode ===
        ServerErrorsEnum.ACCESS_DENIED_FOR_DEPOT_MANAGER
    ) {
      yield call(Auth.ensureSignOut);
    }

    if (payload?.status === 401) {
      errors = messageText && [messageText];
      if (payload?.data.errorMessage === RESPONSE_ERRORS.resetPassword.server) {
        errors = [RESPONSE_ERRORS.resetPassword.client];
      } else {
        yield call(Auth.ensureSignOut);
      }
    }

    const objectMessageText =
      messageText &&
      Array.isArray(messageText) &&
      messageText.filter(
        item => Object.prototype.toString.call(item) === '[object String]'
      );

    const stringMessageText = messageText &&
      typeof messageText === 'string' && [messageText];

    errors = errors || objectMessageText || stringMessageText;

    if (errors && errors.length > 0) {
      yield message.error(errors);
    }
  } catch (e) {
    console.error('Failed error', e);
  }
}

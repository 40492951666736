import {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { taskIdSelector } from 'store/gantt';
import EditTask from '../EditTask';
import TaskBlock from './TaskBlock';
import { TaskWrap } from './styles';
import {
  DragTypeEnum,
  IChecklist,
  IProjectResponseDto,
  IProjectTaskDto,
  ISubtaskTypeDto,
  OnProjectUpdateSubmit,
} from 'types';

interface ITaskInfoProps {
  item?: IProjectTaskDto;
  tasksCount: number;
  index: number;
  sequenceNumber?: number;
  onSubmit: OnProjectUpdateSubmit;
  removeTask: (
    index: number,
    setDeleteLoading: Dispatch<SetStateAction<boolean>>,
    itemId: string
  ) => void;
  setIsOpenEdit?: (open?: boolean) => void;
  openNotify: ({
    isOpen,
    id,
    item,
  }: {
    isOpen: boolean;
    id: string;
    item: IProjectTaskDto;
  }) => void;
  toggleParent?: (visible: boolean) => void;
  openCheckResult: (id: string, percent: number, isDone: boolean) => void;
  updateChecklist?: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => Promise<void>;
  color?: string;
  invoice?: string;
  editProject: ({
    id,
    data,
  }: {
    id: string;
    data: IProjectResponseDto;
  }) => Promise<void>;
  project: IProjectResponseDto;
  isDisabledDelete: boolean;
  setShouldUpdate?: (update: boolean) => void;
  locationId?: string;
  openTaskIndex?: number;
  moveTask: (dragIndex: number, hoverIndex: number) => void;
  saveTasksOrder: () => void;
  notAvailableProject?: boolean;
  subtaskTypes?: ISubtaskTypeDto[];
  isProjectViewTaskMode?: boolean;
  setExpandedTasks: Dispatch<SetStateAction<string[]>>;
  expandedTasks: string[];
  toggleExpandAll: (isOpenAll: boolean) => void;
  isProjectEditing?: boolean;
}

const TaskInfo = ({
  item,
  index,
  sequenceNumber = index + 1,
  onSubmit,
  subtaskTypes,
  setIsOpenEdit,
  openNotify,
  removeTask,
  color,
  invoice,
  isDisabledDelete,
  openCheckResult,
  toggleParent,
  setShouldUpdate,
  locationId,
  moveTask,
  saveTasksOrder,
  openTaskIndex,
  editProject,
  project,
  notAvailableProject,
  isProjectViewTaskMode,
  updateChecklist,
  setExpandedTasks,
  expandedTasks,
  toggleExpandAll,
  tasksCount,
  isProjectEditing,
}: ITaskInfoProps) => {
  const { _id } = item;
  const scrollToRef = useRef(null);
  const ref = useRef(null);
  const taskId = useSelector(taskIdSelector);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultIsOpenInfo = useMemo(() => _id === taskId, [_id]);

  useEffect(() => {
    if (_id === taskId && !expandedTasks.includes(_id)) {
      setExpandedTasks([...expandedTasks, _id]);
    }
  }, [_id, expandedTasks, setExpandedTasks, taskId]);

  const [dragItemStartOrder, setDragItemStartOrder] = useState<number>();
  const isOpenEdit = useMemo(
    () => openTaskIndex === index,
    [openTaskIndex, index]
  );
  const isOpenInfo = useMemo(
    () => expandedTasks.includes(_id),
    [expandedTasks, _id]
  );

  const handleOpenInfo = (open: boolean) => {
    if (open) setExpandedTasks(prev => [...prev, _id]);
    else setExpandedTasks(prev => prev.filter(id => id !== _id));
  };

  useEffect(() => {
    if (defaultIsOpenInfo) scrollToRef.current.scrollIntoView();
  }, [defaultIsOpenInfo]);

  const [{ isDragging }, drag, preview] = useDrag({
    type: item.type || DragTypeEnum.DataTransferItemList,
    item: () => {
      handleOpenInfo(false);
      setIsOpenEdit();
      toggleExpandAll(false);
      setDragItemStartOrder(item.order);
      return { ...item, index };
    },
    canDrag: !isOpenInfo && !notAvailableProject && !isProjectViewTaskMode,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      if (dragItemStartOrder !== item.order) {
        saveTasksOrder();
      }
      toggleExpandAll(false);
    },
  });

  const [, drop] = useDrop({
    accept: 'common',
    hover(itemD: any, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = itemD.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      if (isOpenInfo || isOpenEdit) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      itemD.index = hoverIndex;
      moveTask(dragIndex, hoverIndex);
    },
  });

  drag(drop(ref));
  const opacity = useMemo(() => (isDragging ? 0 : 1), [isDragging]);

  return (
    <TaskWrap ref={scrollToRef} style={{ opacity }}>
      {isOpenEdit ? (
        <EditTask
          index={index}
          sequenceNumber={sequenceNumber}
          item={item}
          isDisabledDelete={isDisabledDelete}
          removeTask={removeTask}
          handleOpenInfo={handleOpenInfo}
          subtaskTypes={subtaskTypes}
          onSubmit={onSubmit}
          notAvailableProject={notAvailableProject}
          setIsOpenEdit={setIsOpenEdit}
          isSingle={isProjectViewTaskMode}
          projectId={project?._id}
        />
      ) : (
        <TaskBlock
          tasksCount={tasksCount}
          setExpandedTasks={setExpandedTasks}
          dragRef={ref}
          previewRef={preview}
          item={item}
          setShouldUpdate={setShouldUpdate}
          index={index}
          setIsOpenEdit={setIsOpenEdit}
          openNotify={openNotify}
          color={color}
          invoice={invoice}
          editProject={editProject}
          project={project}
          openCheckResult={openCheckResult}
          toggleParent={toggleParent}
          isOpenInfo={isOpenInfo}
          handleOpenInfo={handleOpenInfo}
          locationId={locationId}
          notAvailableProject={notAvailableProject}
          updateChecklist={updateChecklist}
          isProjectViewTaskMode={isProjectViewTaskMode}
          isProjectEditing={isProjectEditing}
        />
      )}
    </TaskWrap>
  );
};

export default memo(TaskInfo, isEqual);

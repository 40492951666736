import {
  AdminIcon,
  CompanyIcon,
  DepotIcon,
  ProfileIcon,
  ProjectSettingsIcon,
  ReportsIcon,
  SettingsIcon,
  TimesheetsIcon,
} from 'lib/icons';
import theme from 'styles/theme';
import {
  AdminTabsEnum,
  DepotTabsEnum,
  ModulesTabsEnum,
  ProfileTabsEnum,
  ProjectSettingsTabsEnum,
  SettingsMenuLabelsEnum,
} from 'types';

export const adminSiderItems = [
  {
    key: '/settings/profile',
    label: SettingsMenuLabelsEnum.Profile,
    Icon: <ProfileIcon />,
  },
  {
    key: '/settings/company',
    label: SettingsMenuLabelsEnum.Company,
    Icon: <CompanyIcon />,
  },
  {
    key: '/settings/admin',
    label: SettingsMenuLabelsEnum.Admin,
    Icon: <AdminIcon />,
  },
  {
    key: '/settings/project',
    label: SettingsMenuLabelsEnum.ProjectSettings,
    Icon: <ProjectSettingsIcon />,
  },
  {
    key: '/settings/depot',
    label: SettingsMenuLabelsEnum.DepotSettings,
    Icon: <DepotIcon />,
  },
  {
    key: '/settings/timesheets',
    label: SettingsMenuLabelsEnum.Timesheets,
    Icon: <TimesheetsIcon />,
  },
  {
    key: '/settings/reports',
    label: SettingsMenuLabelsEnum.Reports,
    Icon: <ReportsIcon />,
  },
  {
    key: '/settings/modules',
    label: SettingsMenuLabelsEnum.FeaturesAndIntegrations,
    Icon: <SettingsIcon fill={theme.colors.white} width={16} height={16} />,
  },
];

export const depotManagerSiderItems = [
  {
    key: '/settings/profile',
    label: SettingsMenuLabelsEnum.Profile,
    Icon: <ProfileIcon />,
  },
  {
    key: '/settings/depot',
    label: SettingsMenuLabelsEnum.DepotSettings,
    Icon: <DepotIcon />,
  },
];

export const salesManagerSiderItems = [
  {
    key: '/settings/profile',
    label: SettingsMenuLabelsEnum.Profile,
    Icon: <ProfileIcon />,
  },
];

export const managerSiderItems = [
  {
    key: '/settings/profile',
    label: SettingsMenuLabelsEnum.Profile,
    Icon: <ProfileIcon />,
  },
  {
    key: '/settings/company',
    label: SettingsMenuLabelsEnum.Company,
    Icon: <CompanyIcon />,
  },
  {
    key: '/settings/admin',
    label: SettingsMenuLabelsEnum.Admin,
    Icon: <AdminIcon />,
  },
  {
    key: '/settings/project',
    label: SettingsMenuLabelsEnum.ProjectSettings,
    Icon: <ProjectSettingsIcon />,
  },
  {
    key: '/settings/depot',
    label: SettingsMenuLabelsEnum.DepotSettings,
    Icon: <DepotIcon />,
  },
  {
    key: '/settings/timesheets',
    label: SettingsMenuLabelsEnum.Timesheets,
    Icon: <TimesheetsIcon />,
  },
  {
    key: '/settings/reports',
    label: SettingsMenuLabelsEnum.Reports,
    Icon: <ReportsIcon />,
  },
];
export const schedulerSiderItems = [
  {
    key: '/settings/profile',
    label: SettingsMenuLabelsEnum.Profile,
    Icon: <ProfileIcon />,
  },
  {
    key: '/settings/company',
    label: SettingsMenuLabelsEnum.Company,
    Icon: <CompanyIcon />,
  },
  {
    key: '/settings/admin',
    label: SettingsMenuLabelsEnum.Admin,
    Icon: <AdminIcon />,
  },
  {
    key: '/settings/timesheets',
    label: SettingsMenuLabelsEnum.Timesheets,
    Icon: <TimesheetsIcon />,
  },
  {
    key: '/settings/reports',
    label: SettingsMenuLabelsEnum.Reports,
    Icon: <ReportsIcon />,
  },
];

export const profileItems = [
  {
    key: '/settings/profile/info',
    label: ProfileTabsEnum.MainInfo,
  },
  {
    key: '/settings/profile/password',
    label: ProfileTabsEnum.Password,
  },
];

export const projectSettingsItems = [
  {
    key: '/settings/project/task-types',
    label: ProjectSettingsTabsEnum.TaskTypes,
  },
  {
    key: '/settings/project/recurring-tasks',
    label: ProjectSettingsTabsEnum.RecurringTasks,
  },
  {
    key: '/settings/project/items',
    label: ProjectSettingsTabsEnum.Items,
  },
  {
    key: '/settings/project/project-settings',
    label: ProjectSettingsTabsEnum.ProjectSettings,
  },
];

export const modulesItems = [
  {
    key: '/settings/modules/features',
    label: ModulesTabsEnum.Features,
  },
  // {
  //   key: '/settings/modules/integrations',
  //   label: ModulesTabsEnum.Integrations,
  // },
];

export const managerCompanyItems = [
  {
    key: '/settings/company',
    label: SettingsMenuLabelsEnum.Company,
  },
];

export const adminItems = [
  {
    key: '/settings/admin/admins',
    label: AdminTabsEnum.Admins,
  },
  {
    key: '/settings/admin/resources',
    label: AdminTabsEnum.Resources,
  },
  {
    key: '/settings/admin/groups',
    label: AdminTabsEnum.Groups,
  },
  {
    key: '/settings/admin/email-templates',
    label: AdminTabsEnum.EmailTemplates,
  },
];

export const adminItemsProjectManager = [
  {
    key: '/settings/admin/admins',
    label: AdminTabsEnum.Admins,
  },
  {
    key: '/settings/admin/resources',
    label: AdminTabsEnum.Resources,
  },
  {
    key: '/settings/admin/groups',
    label: AdminTabsEnum.Groups,
  },
  {
    key: '/settings/admin/email-templates',
    label: AdminTabsEnum.EmailTemplates,
  },
];

export const schedulerItemsProjectManager = [
  {
    key: '/settings/admin/resources',
    label: AdminTabsEnum.Resources,
  },
  {
    key: '/settings/admin/groups',
    label: AdminTabsEnum.Groups,
  },
  {
    key: '/settings/admin/email-templates',
    label: AdminTabsEnum.EmailTemplates,
  },
];

export const depotItems = [
  {
    key: '/settings/depot/containers',
    label: DepotTabsEnum.Containers,
  },
  {
    key: '/settings/depot/colors',
    label: DepotTabsEnum.Colors,
  },
  {
    key: '/settings/depot/sizes',
    label: DepotTabsEnum.Sizes,
  },
  {
    key: '/settings/depot/conditions',
    label: DepotTabsEnum.Conditions,
  },
  {
    key: '/settings/depot/grades',
    label: DepotTabsEnum.Grades,
  },
  {
    key: '/settings/depot/companies',
    label: DepotTabsEnum.TruckingCompanies,
  },
];

import { Dispatch, SetStateAction, memo, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { isMobileDevice } from 'utils/helpers';
import BodyBlock from './BodyBlock';
import { BodyDragPreview } from './BodyBlock/BodyDragPreview';
import HeaderBlock from './HeaderBlock';
import objectAssign from 'object-assign';
import theme from 'styles/theme';
import { IChecklist, IProjectResponseDto, IProjectTaskDto } from 'types';

interface ITaskBlockProps {
  item?: IProjectTaskDto;
  index: number;

  setIsOpenEdit: () => void;
  updateChecklist: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => void;
  isOpenInfo: boolean;
  toggleParent?: (visible: boolean) => void;
  openCheckResult: (id: string, percent: number, isDone: boolean) => void;
  handleOpenInfo: (isOpen: boolean) => void;
  openNotify: ({
    isOpen,
    id,
    item,
  }: {
    isOpen: boolean;
    id: string;
    item: IProjectTaskDto;
  }) => void;
  color?: string;
  invoice?: string;
  setShouldUpdate: (update: boolean) => void;
  locationId?: string;
  project: IProjectResponseDto;
  notAvailableProject?: boolean;
  dragRef?: any;
  previewRef?: any;
  editProject: ({
    id,
    data,
  }: {
    id: string;
    data: IProjectResponseDto;
  }) => Promise<void>;
  setExpandedTasks: Dispatch<SetStateAction<string[]>>;
  isProjectViewTaskMode?: boolean;
  tasksCount: number;
  isProjectEditing?: boolean;
}

const TaskBlock = ({
  item,
  index,
  setIsOpenEdit,
  color,
  invoice,
  openCheckResult,
  toggleParent,
  isOpenInfo,
  handleOpenInfo,
  openNotify,
  setShouldUpdate,
  locationId,
  dragRef,
  previewRef,
  editProject,
  project,
  notAvailableProject,
  setExpandedTasks,
  updateChecklist,
  isProjectViewTaskMode,
  tasksCount,
  isProjectEditing,
}: ITaskBlockProps) => {
  const onDragAndDropClick = useCallback(
    e => {
      if (isOpenInfo) {
        setExpandedTasks(prev => prev.filter(id => id !== item._id));
        handleOpenInfo(false);
      }

      e.stopPropagation();
    },
    [isOpenInfo, handleOpenInfo, setExpandedTasks, item._id]
  );

  const isMobile = useMemo(() => isMobileDevice.any(), []);

  const generatePreview = (type, item, style) => {
    objectAssign(style, {
      width: 'calc(100% - 50px)',
      backgroundColor: theme.colors.grayLight,
      border: `1px solid ${theme.colors.grayDark}`,
      borderRadius: '6px',
      WebkitTransform: `${style.transform} scale(1.1, 1.2)`,
      transform: `${style.transform} scale(1.1, 1.2)`,
    });

    return (
      <div style={style}>
        <HeaderBlock
          tasksCount={tasksCount}
          item={item}
          index={index}
          setIsOpenEdit={setIsOpenEdit}
          isOpenInfo={isOpenInfo}
          handleOpenInfo={handleOpenInfo}
          openNotify={openNotify}
          dragRef={dragRef}
          notAvailableProject={notAvailableProject}
          toggleParent={toggleParent}
          color={color}
          projectId={project._id}
          invoice={invoice}
          isProjectEditing={isProjectEditing}
        />
      </div>
    );
  };

  return (
    <div ref={previewRef}>
      <div onClick={onDragAndDropClick} ref={dragRef}>
        <HeaderBlock
          tasksCount={tasksCount}
          item={item}
          index={index}
          setIsOpenEdit={setIsOpenEdit}
          isOpenInfo={isOpenInfo}
          handleOpenInfo={handleOpenInfo}
          openNotify={openNotify}
          dragRef={dragRef}
          notAvailableProject={notAvailableProject}
          toggleParent={toggleParent}
          color={color}
          projectId={project._id}
          invoice={invoice}
          isProjectViewTaskMode={isProjectViewTaskMode}
          isProjectEditing={isProjectEditing}
        />
        <BodyBlock
          isProjectViewTaskMode={isProjectViewTaskMode}
          item={item}
          editProject={editProject}
          index={index}
          setIsOpenEdit={setIsOpenEdit}
          color={color}
          invoice={invoice}
          project={project}
          openCheckResult={openCheckResult}
          toggleParent={toggleParent}
          isOpenInfo={isOpenInfo}
          setShouldUpdate={setShouldUpdate}
          locationId={locationId}
          notAvailableProject={notAvailableProject}
          updateChecklist={updateChecklist}
          isProjectEditing={isProjectEditing}
        />
        {isMobile && <BodyDragPreview generator={generatePreview} />}
      </div>
    </div>
  );
};

export default memo(TaskBlock, isEqual);

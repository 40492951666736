export const PDF_CONTENT_TYPE = 'application/pdf';

export const PNG_CONTENT_TYPE = 'image/png';

export const GIF_CONTENT_TYPE = 'image/gif';

export const WEBP_CONTENT_TYPE = 'image/webp';

export const JPG_CONTENT_TYPE = 'image/jpeg';

export const BMP_CONTENT_TYPE = 'image/bmp';

export const SVG_CONTENT_TYPE = 'image/svg+xml';

export const MP4_CONTENT_TYPE = 'video/mp4';

export const AUTOCAD_CONTENT_EXTENSIONS = ['.dwg', '.dxf', '.dwf'];

export const PROJECT_UPLOAD_FILE_TYPES = [
  PDF_CONTENT_TYPE,
  JPG_CONTENT_TYPE,
  PNG_CONTENT_TYPE,
  MP4_CONTENT_TYPE,
  ...AUTOCAD_CONTENT_EXTENSIONS,
];

export const IMAGES_MIME_TYPES = [
  JPG_CONTENT_TYPE,
  PNG_CONTENT_TYPE,
  WEBP_CONTENT_TYPE,
];

export const IMAGES_MIME_TYPES_WITHOUT_RESIZE = [
  GIF_CONTENT_TYPE,
  SVG_CONTENT_TYPE,
  BMP_CONTENT_TYPE,
];

export const COVER_MIME_TYPES = [
  JPG_CONTENT_TYPE,
  PNG_CONTENT_TYPE,
  PDF_CONTENT_TYPE,
  SVG_CONTENT_TYPE,
  BMP_CONTENT_TYPE,
  WEBP_CONTENT_TYPE,
  GIF_CONTENT_TYPE,
];

export const MIME_TYPES_HAS_PREVIEW = [...IMAGES_MIME_TYPES, PDF_CONTENT_TYPE];

export const getFilePreviewUrl = (file, getResizeUrl) => {
  if (IMAGES_MIME_TYPES.includes(file?.mimetype)) {
    return getResizeUrl(file?.fileUrl);
  } else if (file?.mimetype === PDF_CONTENT_TYPE) {
    return getResizeUrl(file?.previewUrl);
  } else if (IMAGES_MIME_TYPES_WITHOUT_RESIZE.includes(file?.mimetype)) {
    return file?.fileUrl;
  } else {
    return undefined;
  }
};

export const MAX_FILE_SIZE_MB = 25;

import { push } from 'connected-react-router';
import { MouseEvent, memo } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/auth';
import { companySelector } from 'store/company';
import {
  setScreenDistribution,
  splitScreen,
  splitScreenSelector,
} from 'store/dashboard';
import { workOrdersInfoSelector } from 'store/inbound';
import { getAvatarProps } from 'utils/helpers';
import FullScreenSwitch from '../FullScreenSwitch';
import ShortCuts from '../ShortCuts/ShortCuts';
import { NotificationsButton } from './BarNotifications';
import PrintAllButton from './PrintAllButton';
import {
  BUTTON_TYPES,
  Button,
  InboundButton,
  LinkButton,
  Text,
} from 'components/common';
import { ButtonSwitch } from 'components/common/ButtonSwitch';
import { useActions } from 'hooks';
import { PlusIcon, Separator, SettingsIcon } from 'lib/icons';
import theme from 'styles/theme';
import { Avatar, Col } from 'antd';

interface IRightPanelProps {
  isDepotManager?: boolean;
  isSalesManager?: boolean;
  openInboundModal?: () => void;
  createProject?: () => void;
}

const RightPanel = ({
  isDepotManager,
  openInboundModal,
  createProject,
  isSalesManager,
}: IRightPanelProps) => {
  const { num: workOrdersNum } = useSelector(workOrdersInfoSelector);
  const user = useSelector(userSelector);
  const isScreenSplitted = useSelector(splitScreenSelector);
  const pushTo = useActions(push);
  const company = useSelector(companySelector);
  const splitScreenAction = useActions(splitScreen);
  const handleSetScreenDistribution = useActions(setScreenDistribution);
  const { fullName, color, background } = getAvatarProps(user?.firstName);

  const handleSplitScreen = () => {
    splitScreenAction({ isScreenSplitted: !isScreenSplitted });
    handleSetScreenDistribution('half');
  };

  return (
    <Col className="right-col">
      {!isDepotManager && !isSalesManager && (
        <>
          <PrintAllButton />
          <FullScreenSwitch />
          <ButtonSwitch
            onClick={handleSplitScreen}
            fontSize="13px"
            height="30px"
            lineheight="0px"
            space="4px"
            label="Split Screen"
            isButtonActive={isScreenSplitted}
          />
          {company?.isInboundWOEnabled && (
            <InboundButton
              onClick={openInboundModal}
              label="Inbound WO"
              param={workOrdersNum || 0}
              className="margin"
            />
          )}

          <LinkButton
            width="91px"
            height="30px"
            kind={BUTTON_TYPES.SECONDARY}
            label="Inventory"
            style={{ marginLeft: 10 }}
            to="/inventory"
            lineheight="0px"
          />
          <Button
            icon={<PlusIcon color={theme.colors.white} />}
            onClick={createProject}
            label="Create Project"
            kind={BUTTON_TYPES.DEFAULT}
            fontSize="13px"
            height="30px"
            lineheight="0px"
            space="4px"
            className="margin"
          />
          <Separator className="separator-right margin" />
        </>
      )}

      {!isDepotManager && (
        <>
          <NotificationsButton />
          <Separator className="separator-right" />
          <LinkButton
            icon={<SettingsIcon />}
            kind={BUTTON_TYPES.ICON}
            space="0"
            to="/settings"
            onClick={(e: MouseEvent<HTMLButtonElement>) =>
              e.currentTarget.blur()
            }
          />
          <Separator className="separator-right " />
        </>
      )}

      <Text
        ellipsis={{ rows: 1 }}
        className="margin"
        onClick={() => pushTo('/settings/profile')}
        style={{ cursor: 'pointer', maxWidth: '135px' }}
      >{`${user.firstName} ${user.lastName}`}</Text>
      <Avatar
        shape="square"
        size={33}
        className="avatar"
        src={user?.photoUrl}
        // @ts-ignore
        onClick={() => pushTo('/settings/profile')}
        style={{ color, background }}
      >
        {fullName}
      </Avatar>
      {!isDepotManager && <ShortCuts />}
    </Col>
  );
};

export default memo(RightPanel, isEqual);

import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const TaskItem = styled.div`
  margin: 0 20px 0 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
`;

export const TaskInfoWrapper = styled.div<{
  $isOpenInfo?: boolean;
}>`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  ${({ $isOpenInfo }) =>
    !$isOpenInfo &&
    css`
      justify-content: flex-end;
      padding-right: 11px;
      @media (max-width: 500px) {
        display: none;
      }
      ${TaskItem} {
        margin: 0 0 0 20px;
      }
    `}
`;

export const TaskTitle = styled.span`
  color: ${theme.colors.lightBlue};
`;
export const TaskData = styled.span`
  margin-left: 4px;
  color: ${theme.colors.mainDarkBlue};
`;

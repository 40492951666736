import styled, { css } from 'styled-components';
import { Progress } from 'antd';

export const ProgressStyled = styled(Progress)<{
  withtitle?: string;
  large?: string;
}>`
  line-height: ${({ large }) => (large ? '12px' : '3px')};
  .ant-progress-outer {
    line-height: ${({ large }) => (large ? '12px' : '3px')};
  }
  .ant-progress-inner {
    margin-bottom: 0;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-progress-bg,
  .ant-progress-success-bg {
    height: 3px !important;
  }
  .ant-progress-bg {
    background-color: ${({ theme }) => theme.colors.lightDeepBlue};
  }
  .ant-progress-success-bg {
    background-color: ${({ theme }) => theme.colors.eucalyptus};
  }
  ${props =>
    props.large &&
    css`
      .ant-progress-inner {
        width: 100%;
        height: 12px;
      }
      .ant-progress-bg,
      .ant-progress-success-bg {
        height: 12px !important;
      }
    `};
  width: ${({ withtitle }) => withtitle === 'true' && 130}px;
`;

export const Title = styled.div<{ isOpen?: boolean; large: string }>`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 500;
  font-size: ${({ large }) => (large ? '14px' : '12px')};
  line-height: ${({ large }) => (large ? '17px' : '15px')};
  max-width: ${({ large }) => (large ? '100%' : '290px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.isOpen &&
    css`
      max-width: 360px;
    `};
`;

export const Percent = styled.div<{ roundPercent?: number }>`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  width: 10%;
  line-height: 16px;
  margin-left: 7px;
  color: ${({ theme, roundPercent }) =>
    roundPercent === 0
      ? theme.colors.textGreyDark
      : roundPercent === 100
      ? theme.colors.eucalyptus
      : theme.colors.lightDeepBlue};
`;

export const ProgressRow = styled.div<{ large?: string }>`
  display: flex;
  margin-top: ${({ large }) => (large ? '5x' : '0')};
  align-items: center;
`;

import { memo, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import {
  markSubtaskAsActivePromiseCreator,
  markSubtaskAsDonePromiseCreator,
  markSubtaskAsNotReadyPromiseCreator,
  markSubtaskAsPausedPromiseCreator,
  markSubtaskAsToDoPromiseCreator,
  putProjectPromiseCreator,
} from 'store/dashboard';
import SetStatus from 'components/SubtaskViewComponents/SetStatus';
import { useActionsRoutines } from 'hooks';
import { useMemoCompare } from 'hooks/useMemoCompare';
import {
  PROJECTS_STATUSES,
  SUBTASK_STATUSES_PROPERTIES_LIST,
} from 'lib/constants';
import { RESOURCE_HAS_ACTIVE_SUBTASK } from 'lib/constants/errors';
import CollapseIcon from 'lib/icons/CollapseIcon';
import { Popover } from 'antd';
import { DoubleArrowWrap, Status } from './styles';
import { IConflictSubtaskErorr, ISubtaskDto, SubtaskStatusesEnum } from 'types';

interface ISetStatusPopoverProps {
  data: ISubtaskDto;
  projectStatus?: string;
  notAvailableProject: boolean;
  setShouldUpdate: (update: boolean) => void;
  isMobile?: boolean;
}
const SetStatusPopoverComponent = ({
  data,
  projectStatus,
  notAvailableProject,
  setShouldUpdate,
  isMobile,
}: ISetStatusPopoverProps) => {
  const { _id, status, percent } = data;

  const putProject = useActionsRoutines(putProjectPromiseCreator);
  const markSubtaskAsToDo = useActionsRoutines(markSubtaskAsToDoPromiseCreator);
  const markSubtaskAsDone = useActionsRoutines(markSubtaskAsDonePromiseCreator);
  const markSubtaskAsActive = useActionsRoutines(
    markSubtaskAsActivePromiseCreator
  );
  const markSubtaskAsNotReady = useActionsRoutines(
    markSubtaskAsNotReadyPromiseCreator
  );
  const markSubtaskAsPaused = useActionsRoutines(
    markSubtaskAsPausedPromiseCreator
  );

  const [isSetStatusVisible, setSetStatusVisible] = useState(false);
  const [conflictSubtask, setConflictSubtask] = useState<
    (IConflictSubtaskErorr & { resumeActionDate: string }) | undefined
  >();

  const handleVisibleChange = useCallback(val => {
    setSetStatusVisible(val);
    if (!val) setConflictSubtask(undefined);
  }, []);

  const memoizedConflictSubtask = useMemoCompare(conflictSubtask);
  const subtaskStatusRequest = useCallback(
    async (newStatus, date, prevStatus, percent?) => {
      if (
        memoizedConflictSubtask &&
        newStatus === SubtaskStatusesEnum.IN_PROGRESS
      ) {
        return markSubtaskAsPaused({
          id: memoizedConflictSubtask.subtaskId,
          date,
          percent,
          isConflict: true,
        })
          .then(async () => {
            setConflictSubtask(undefined);

            return markSubtaskAsActive({
              id: _id,
              date: memoizedConflictSubtask.resumeActionDate,
            });
          })
          .catch(err => {
            console.error(err);

            return false;
          });
      }

      switch (newStatus) {
        case SubtaskStatusesEnum.DONE:
          return markSubtaskAsDone({ id: _id, date });
        case SubtaskStatusesEnum.TO_DO:
          return markSubtaskAsToDo({
            id: _id,
          });
        case SubtaskStatusesEnum.IN_PROGRESS:
          return markSubtaskAsActive({ id: _id, date });
        case SubtaskStatusesEnum.PAUSED:
          return markSubtaskAsPaused({ id: _id, date, percent });
        default:
          return markSubtaskAsNotReady(_id);
      }
    },
    [
      _id,
      markSubtaskAsDone,
      markSubtaskAsToDo,
      markSubtaskAsActive,
      markSubtaskAsNotReady,
      markSubtaskAsPaused,
      memoizedConflictSubtask,
    ]
  );

  const onSubtaskStatusChange = useCallback(
    (newStatus, setIsLoading, date, prevStatus, percent?) => {
      if (notAvailableProject) return;
      const shouldChangeProjectStatus = prevStatus === SubtaskStatusesEnum.DONE;
      subtaskStatusRequest(newStatus, date, prevStatus, percent)
        .then(res => {
          const project = res?.project;
          if (project) {
            setShouldUpdate(true);
            setSetStatusVisible(false);
            if (shouldChangeProjectStatus) {
              putProject({
                id: project._id,
                data: {
                  ...project,
                  status: PROJECTS_STATUSES.NOT_STARTED,
                  files: project?.files,
                  tasks: project?.tasks?.map((item, index: number) => ({
                    ...item,
                    order: index + 1,
                  })),
                },
              }).catch(e => console.error(e));
            }
          }
        })
        .catch(err => {
          if (err?.data?.errorCode === RESOURCE_HAS_ACTIVE_SUBTASK) {
            setConflictSubtask({
              ...err.data,
              resumeActionDate: date,
            });
          } else {
            console.error(err);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [subtaskStatusRequest, setShouldUpdate, notAvailableProject, putProject]
  );

  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );

  const popoverContent = (
    <SetStatus
      conflictSubtask={conflictSubtask as IConflictSubtaskErorr}
      subtaskStartDate={data?.startDate}
      defaultPercent={percent}
      onSubmit={onSubtaskStatusChange}
      status={status}
      datePickerWidth={isMobile ? 200 : undefined}
    />
  );

  const statusPreset = SUBTASK_STATUSES_PROPERTIES_LIST.find(
    item => item.value === status
  );

  const StatusIcon = statusPreset.icon;

  const isPaused = status === SubtaskStatusesEnum.PAUSED;

  const isStatusDisabled = [
    PROJECTS_STATUSES.WAITING_FOR_CTNR,
    PROJECTS_STATUSES.WAITING_FOR_MATERIALS,
    PROJECTS_STATUSES.WAITING_FOR_SALES_RE_WORK,
  ].includes(projectStatus);

  return (
    <Popover
      getPopupContainer={handleGetPopupContainer}
      placement="bottomRight"
      visible={isSetStatusVisible && !notAvailableProject && !isPaused}
      onVisibleChange={handleVisibleChange}
      content={popoverContent}
      destroyTooltipOnHide
      trigger="click"
    >
      <Status
        $isPaused={isPaused}
        $notAvailableProject={notAvailableProject}
        statusPreset={statusPreset}
        $disabled={isStatusDisabled}
      >
        {statusPreset.icon && <StatusIcon />}
        <span> {status}</span>
        {!notAvailableProject && (
          <DoubleArrowWrap
            statusPreset={statusPreset}
            isOpen={isSetStatusVisible}
            disabled={isStatusDisabled}
          >
            <CollapseIcon />
          </DoubleArrowWrap>
        )}
      </Status>
    </Popover>
  );
};

export const SetStatusPopover = memo(SetStatusPopoverComponent, isEqual);

export enum RoleEnum {
  SuperAdministrator = 'Super Administrator',
  Administrator = 'Administrator',
  ProductionManager = 'Production manager',
  Scheduler = 'Scheduler',
  DepotManager = 'Depot manager',
  SalesManager = 'Sales manager',
}

export enum UserRolesEnum {
  SUPER_ADMIN = RoleEnum.SuperAdministrator,
  ADMIN = RoleEnum.Administrator,
  PROD_MANAGER = RoleEnum.ProductionManager,
  SCHEDULER = RoleEnum.Scheduler,
  DEPOT_MANAGER = RoleEnum.DepotManager,
  SALES_MANAGER = RoleEnum.SalesManager,
}

export const USER_MAIN_ROLES = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  PROD_MANAGER: 'manager',
  SCHEDULER: 'scheduler',
  DEPOT_MANAGER: 'depotManager',
  PROD_AND_DEPOT_MANAGER: 'managerAndDepotManager',
  SALES_MANAGER: 'salesManager',
};

export enum UpdatedRolesEnum {
  ADMIN = 'Admin',
  SUPER_ADMIN = 'Super Admin',
}

import { useCallback, useMemo } from 'react';
import { TASK_STATUSES } from 'lib/constants/tasks';
import { Percent, ProgressRow, ProgressStyled, Title } from './styles';

interface IProgressBarProps {
  item?: any;
  withTitle?: boolean;
  isOpen?: boolean;
  isLarge?: boolean;
}

const ProgressBar = ({
  item,
  withTitle,
  isOpen,
  isLarge,
}: IProgressBarProps) => {
  const sumProgress =
    +item.doneCount +
    +item.inProgressCount +
    +item.todoCount +
    +item.notReadyCount;

  const name = `#${item.order} ${item.name}`;

  const getPercent = useCallback(
    number => {
      if (item?.status === TASK_STATUSES.DONE) return 0;
      return (number * 100) / sumProgress;
    },
    [item, sumProgress]
  );

  const getSuccessPercent = useCallback(
    number => {
      if (item?.status === TASK_STATUSES.DONE) return 100;
      return (number * 100) / sumProgress;
    },
    [item, sumProgress]
  );

  const percent = getPercent(
    Number(item.inProgressCount) + Number(item.doneCount)
  );
  const successPercent = getSuccessPercent(item.doneCount);
  const roundPercent = Math.round(successPercent);
  const percentShow = useMemo(
    () => (roundPercent === 100 || !sumProgress ? 'Done' : `${roundPercent}%`),
    [roundPercent, sumProgress]
  );

  const large = useMemo(() => (isLarge ? 'true' : ''), [isLarge]);

  return (
    <>
      {withTitle && (
        <Title large={large} isOpen={isOpen}>
          {name}
        </Title>
      )}
      {!isOpen && (
        <ProgressRow large={large}>
          <ProgressStyled
            large={large}
            percent={percent}
            success={{ percent: successPercent }}
            showInfo={false}
            withtitle={withTitle?.toString()}
          />
          {isLarge && (
            <Percent roundPercent={roundPercent}>{percentShow}</Percent>
          )}
        </ProgressRow>
      )}
    </>
  );
};

export default ProgressBar;

import { push } from 'connected-react-router';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as Admin from 'store/admin';
import { emailTemplatesSaga } from 'store/admin/emailTemplates/sagas';
// redux
import {
  currentLocationSelector,
  isAdminSelector,
  isDepotManagerSelector,
  isSalesManagerSelector,
  signIn,
} from 'store/auth';
import * as Company from 'store/company';
import * as Dashboard from 'store/dashboard';
import * as Inbount from 'store/inbound';
import { actions } from 'store/loading';
import * as Predefined from 'store/predefined';
import * as SubtaskTypes from 'store/project/subtaskTypes';
import * as Tasks from 'store/project/tasks';
import { ROUTES } from 'store/router';
import { watchSignUp } from './watchers';
import * as E from './workers';
import storageManager from 'utils/storageManager';
import { ProjectViewMode } from 'types';

export function* watchInitApp() {
  const projectViewMode = storageManager.getProjectViewMode();
  const isDepot = yield select(isDepotManagerSelector);
  const isSales = yield select(isSalesManagerSelector);
  const isAdmin = yield select(isAdminSelector);
  const company = yield select(Company.companySelector);

  if (isAdmin) yield put(Admin.containerOptions.request());

  yield put(
    Dashboard.setProjectViewMode(
      (projectViewMode as ProjectViewMode) || ProjectViewMode.Tasks
    )
  );

  if (company?.deleted) {
    yield put(push(ROUTES.RESTORE));
    return;
  }

  yield call(Predefined.ensureLocations);

  if (isDepot) {
    const { isContainersEnabled } = company;
    if (typeof isContainersEnabled !== 'undefined' && !isContainersEnabled) {
      yield call(E.ensureSignOut);
    }
    return;
  }

  if (isSales) {
    yield fork(watchSales);
  } else {
    yield fork(watchRoles);
    yield fork(emailTemplatesSaga);
    const updatedCompany = yield select(Company.companySelector);
    if (updatedCompany?.isContainersEnabled) {
      yield call(Tasks.ensureDefaultContainerTemplate);
      yield call(SubtaskTypes.ensureGetSubtaskTypesDefaults);
    }
  }
  yield put(actions.loading(false));
}

function* watchSales() {
  yield all([
    fork(Predefined.watchLocationsActions),
    fork(Predefined.watchRoles),
    fork(Company.watchCompany),
  ]);
  const currentLocationId = yield select(currentLocationSelector);
  yield call(E.ensureCurrentLocation as any, currentLocationId);
}

function* watchRoles() {
  yield all([
    fork(Predefined.watchLocationsActions),
    fork(Predefined.watchRoles),
    fork(Company.watchCompany),
    fork(Company.watchDeleteCompany),
    fork(Company.watchPatchCompany),
    fork(Company.watchDeleteSuperAdmin),
    fork(Admin.watchGetSubtaskTypes),
    fork(Admin.watchAddSubtaskType),
    fork(Admin.watchAddSubtaskTypeWithChecklist),
    fork(Admin.watchUpdateTask),
    fork(Admin.watchPatchTask),
    fork(Admin.watchSetSubtaskTypesSearch),
    fork(Admin.watchExportTasksCsv),
    fork(Admin.watchExportSampleCsv),
    fork(Admin.watchImportTasksCsv),
    fork(Dashboard.mainRoot),
    fork(Inbount.inboundRoot),
    fork(Admin.watchGetTasksAutocomplete),
    fork(Admin.watchContainerOptions),
    fork(Admin.watchGetResourcesList),
  ]);
  const currentLocationId = yield select(currentLocationSelector);
  const company = yield select(Company.companySelector);
  yield call(E.ensureCurrentLocation as any, currentLocationId);
  if (company.isInboundWOEnabled) {
    yield call(Inbount.ensureWorkOrdersInfo);
  }
}

export function* watchSignIn() {
  yield takeLatest(signIn.SUCCESS, watchInitApp);
}

export function* watchRestoreCompany() {
  yield takeLatest(Company.restoreCompany.SUCCESS, watchInitApp);
}

export function* watchRegisterNewCompany() {
  yield takeLatest(Company.registerNewCompany.SUCCESS, watchInitApp);
}

export function* watchSignInFailure() {
  yield takeLatest(signIn.FAILURE, E.ensureSignInFailure);
}

export function* signupSaga() {
  yield all([
    fork(Company.watchPatchUserAdmin),
    fork(Predefined.watchPatchLocation),
    fork(watchSignUp),
  ]);
}

import { call, put, select } from 'redux-saga/effects';
import { subtaskTypesSelector } from 'store/admin';
import { isDepotManagerSelector } from 'store/auth';
import { actions } from 'store/loading';
import { subtaskTypesSearchSelector } from '../../admin/selectors';
// redux
import * as Actions from './actions';
// utils
import Api from 'utils/api';
// constants
import {
  CreateChecklistTemplateDto,
  CreateChecklistTemplateItemDto,
  IEnsureAddSubtaskTypeWithChecklistPayload,
  IEnsureDeleteSubtaskTypePayload,
  IEnsureGetSubtaskTypesPayload,
  IEnsureUpdateChecklistItemPayload,
  ISubtaskTypeDto,
} from 'types';

export function* ensureGetSubtaskTypes({
  payload = {},
}: {
  payload?: IEnsureGetSubtaskTypesPayload;
  type: typeof Actions.subtaskTypes.TRIGGER;
}) {
  const isDepot = yield select(isDepotManagerSelector);
  if (isDepot) return;

  const subtaskTypes = yield select(subtaskTypesSelector);
  const subtaskTypesSearch = yield select(subtaskTypesSearchSelector);

  const searchName =
    typeof payload?.params?.name === 'string'
      ? payload?.params?.name
      : subtaskTypesSearch;

  const noLoader =
    typeof payload?.noLoader === 'undefined'
      ? !payload?.params && subtaskTypes?.length
      : payload?.noLoader;

  const params = {
    ...payload?.params,
    name: searchName,
  };

  try {
    if (!noLoader) yield put(actions.tabLoading(true));

    const data: ISubtaskTypeDto[] = yield call(Api.subtaskTypes.subtaskTypes, {
      params,
    });
    yield put(
      Actions.subtaskTypes.success({
        data,
        withSearch: !!searchName,
      })
    );

    yield call(ensureSetSubtaskTypesSearch, {
      payload: searchName,
      type: Actions.setSubtaskTypesSearch.TRIGGER,
    });
  } catch (err) {
    yield put(Actions.subtaskTypes.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureGetSubtaskType({
  payload,
}: {
  payload: string;
  type: typeof Actions.getSubtaskType.TRIGGER;
}) {
  try {
    const data: ISubtaskTypeDto[] = yield call(
      Api.subtaskTypes.getSubtaskType,
      payload
    );
    yield put(Actions.getSubtaskType.success(data));
  } catch (err) {
    yield put(Actions.getSubtaskType.failure(err));
  }
}

export function* ensureUpdateSubtaskType({
  payload,
}: {
  payload: {
    data: {
      id: string;
      data: ISubtaskTypeDto;
    };
  };
  type: typeof Actions.updateSubtaskType.request;
}) {
  try {
    const subtaskTypesSearch = yield select(subtaskTypesSearchSelector);
    const data: ISubtaskTypeDto = yield call(
      Api.subtaskTypes.putSubtaskType,
      payload.data
    );
    yield put(Actions.updateSubtaskType.success(data));
    if (subtaskTypesSearch) {
      yield call(ensureGetSubtaskTypes, {
        payload: { params: { name: subtaskTypesSearch } },
        type: Actions.subtaskTypes.TRIGGER,
      });
    } else {
      yield call(ensureGetSubtaskTypes, {
        type: Actions.subtaskTypes.TRIGGER,
      });
    }
  } catch (err) {
    yield put(Actions.updateSubtaskType.failure(err));
  }
}

export function* ensureGetSubtaskTypesDefaults() {
  try {
    const data: ISubtaskTypeDto[] = yield call(
      Api.subtaskTypes.subtaskTypesDefaults
    );
    yield put(Actions.subtaskTypesDefaults.success(data));
  } catch (err) {
    yield put(Actions.subtaskTypesDefaults.failure(err));
  }
}

export function* ensureAddSubtaskType({
  payload,
}: {
  payload: {
    data: ISubtaskTypeDto;
  };
  type: typeof Actions.addSubtaskType.TRIGGER;
}) {
  try {
    const search = yield select(subtaskTypesSearchSelector);
    const data: ISubtaskTypeDto = yield call(
      Api.subtaskTypes.postSubtaskTypes,
      payload.data
    );
    yield put(Actions.addSubtaskType.success(data));
    if (search) {
      yield call(ensureGetSubtaskTypes, {
        payload: { params: { name: search } },
        type: Actions.subtaskTypes.TRIGGER,
      });
    } else {
      yield call(ensureGetSubtaskTypes, {
        type: Actions.subtaskTypes.TRIGGER,
      });
    }
  } catch (err) {
    yield put(Actions.addSubtaskType.failure(err));
  }
}

export function* ensureAddSubtaskTypeWithChecklist({
  payload,
}: {
  payload: IEnsureAddSubtaskTypeWithChecklistPayload;
  type: typeof Actions.addSubtaskTypeWithChecklist.TRIGGER;
}) {
  try {
    const { data, subtaskChecklist } = payload;
    const res = yield call(Api.subtaskTypes.postSubtaskTypes, data);
    yield put(Actions.addSubtaskTypeWithChecklist.success(res));

    if (!!subtaskChecklist.length) {
      const checklistData = subtaskChecklist.map(item => ({
        title: item.title,
        subtaskTypeId: res._id,
      }));
      yield call(ensureAddBulkChecklist, {
        payload: { checklist: checklistData },
        type: Actions.addBulkChecklist.TRIGGER,
      });
    } else {
      yield call(ensureGetSubtaskTypes, {
        type: Actions.subtaskTypes.TRIGGER,
      });
    }
  } catch (err) {
    yield put(Actions.addSubtaskTypeWithChecklist.failure(err));
  }
}

export function* ensureDeleteSubtaskType({
  payload,
}: {
  payload: IEnsureDeleteSubtaskTypePayload;
  type: typeof Actions.deleteSubtaskType.TRIGGER;
}) {
  try {
    yield call(Api.subtaskTypes.deleteSubtaskType, payload.id);
    yield put(Actions.deleteSubtaskType.success(payload));
  } catch (err) {
    yield put(Actions.deleteSubtaskType.failure(err));
  }
}

export function* ensureSetSubtaskTypesSearch({
  payload = '',
}: {
  payload: string;
  type: typeof Actions.setSubtaskTypesSearch.TRIGGER;
}) {
  try {
    yield put(Actions.setSubtaskTypesSearch.success(payload));
  } catch (err) {
    yield put(Actions.setSubtaskTypesSearch.failure(err));
  }
}

export function* ensureGetChecklistItem({
  payload,
}: {
  payload: string;
  type: typeof Actions.getChecklistItem.TRIGGER;
}) {
  try {
    yield call(Api.subtaskChecklist.get, payload);
    yield put(Actions.getChecklistItem.success(payload));
    yield call(ensureGetSubtaskTypes, {
      type: Actions.subtaskTypes.TRIGGER,
    });
  } catch (err) {
    yield put(Actions.getChecklistItem.failure(err));
  }
}

export function* ensureAddChecklistItem({
  payload,
}: {
  payload: CreateChecklistTemplateItemDto;
  type: typeof Actions.addChecklistItem.TRIGGER;
}) {
  try {
    yield call(Api.subtaskChecklist.create, payload);
    yield put(Actions.addChecklistItem.success(payload));
    yield call(ensureGetSubtaskTypes, {
      type: Actions.subtaskTypes.TRIGGER,
    });
  } catch (err) {
    yield put(Actions.addChecklistItem.failure(err));
  }
}

export function* ensureAddBulkChecklist({
  payload,
}: {
  payload: CreateChecklistTemplateDto;
  type: typeof Actions.addBulkChecklist.TRIGGER;
}) {
  try {
    yield call(Api.subtaskChecklist.bulk, payload);
    yield put(Actions.addBulkChecklist.success(payload));
    yield call(ensureGetSubtaskTypes, {
      type: Actions.subtaskTypes.TRIGGER,
    });
  } catch (err) {
    yield put(Actions.addBulkChecklist.failure(err));
  }
}

export function* ensureUpdateChecklistItem({
  payload,
}: {
  payload: IEnsureUpdateChecklistItemPayload;
  type: typeof Actions.updateChecklistItem.TRIGGER;
}) {
  try {
    yield call(Api.subtaskChecklist.update, payload);
    yield put(Actions.updateChecklistItem.success(payload));
    yield call(ensureGetSubtaskTypes, {
      type: Actions.subtaskTypes.TRIGGER,
    });
  } catch (err) {
    yield put(Actions.updateChecklistItem.failure(err));
  }
}

export function* ensureDeleteChecklistItem({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteChecklistItem.TRIGGER;
}) {
  try {
    yield call(Api.subtaskChecklist.delete, payload);
    yield put(Actions.deleteChecklistItem.success(payload));
    yield call(ensureGetSubtaskTypes, {
      type: Actions.subtaskTypes.TRIGGER,
    });
  } catch (err) {
    yield put(Actions.deleteChecklistItem.failure(err));
  }
}

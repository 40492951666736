import { TaskData, TaskInfoWrapper, TaskItem, TaskTitle } from './styles';

interface ITaskShortInfo {
  sku?: string;
  cost: number;
  price: number;
  isOpenInfo: boolean;
}

export const TaskShortInfo = ({
  isOpenInfo,
  sku,
  cost,
  price,
}: ITaskShortInfo): JSX.Element => (
  <TaskInfoWrapper $isOpenInfo={isOpenInfo}>
    {sku && (
      <TaskItem>
        <TaskTitle>SKU</TaskTitle>
        <TaskData>{sku}</TaskData>
      </TaskItem>
    )}
    <TaskItem>
      <TaskTitle>Cost</TaskTitle>
      <TaskData>${cost}</TaskData>
    </TaskItem>
    <TaskItem>
      <TaskTitle>Sale price</TaskTitle>
      <TaskData>${price}</TaskData>
    </TaskItem>
  </TaskInfoWrapper>
);

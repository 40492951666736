import * as yup from 'yup';
import {
  description,
  itemName,
  notes,
  plannedHours,
  projectPrice,
  sku,
} from './baseSchema';

const projectTaskSchema = yup.object().shape({
  name: itemName,
  sku,
  cost: projectPrice,
  price: projectPrice,
  description,
  subtasks: yup.array().of(
    yup.object().shape({
      order: yup.number().required('Required!').typeError('Required!'),
      plannedHours,
      name: yup.string().required('Task Name is required!'),
      notes,
      checklist: yup.array().of(
        yup.object().shape({
          _id: yup.string(),
          title: yup.string(),
          isCompleted: yup.boolean(),
        })
      ),
    })
  ),
});

export default projectTaskSchema;

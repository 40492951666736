import { USER_MAIN_ROLES, UpdatedRolesEnum, UserRolesEnum } from 'types';

export const getRole = roles => {
  let mainRole;
  switch (true) {
    case roles.indexOf(UserRolesEnum.SUPER_ADMIN) !== -1:
      mainRole = USER_MAIN_ROLES.SUPER_ADMIN;
      break;
    case roles.indexOf(UserRolesEnum.ADMIN) !== -1:
      mainRole = USER_MAIN_ROLES.ADMIN;
      break;
    case roles.indexOf(UserRolesEnum.SALES_MANAGER) !== -1:
      mainRole = USER_MAIN_ROLES.SALES_MANAGER;
      break;
    case roles.indexOf(UserRolesEnum.PROD_MANAGER) !== -1:
      mainRole = USER_MAIN_ROLES.PROD_MANAGER;
      break;
    case roles.indexOf(UserRolesEnum.SCHEDULER) !== -1:
      mainRole = USER_MAIN_ROLES.SCHEDULER;
      break;
    case roles.indexOf(UserRolesEnum.DEPOT_MANAGER) !== -1:
      mainRole = USER_MAIN_ROLES.DEPOT_MANAGER;
      break;
    default:
      mainRole = USER_MAIN_ROLES.ADMIN;
  }
  return { mainRole };
};

export const isSuperAdminRole = roles =>
  !!roles?.find(role => role === UpdatedRolesEnum.SUPER_ADMIN);

import { call, put, select } from 'redux-saga/effects';
import { currentLocationSelector, isDepotManagerSelector } from 'store/auth';
import {
  deleteSubtaskFiles,
  ensureDeleteSubtaskFiles,
  ensureProjects,
  projects,
  subtaskFilesBufferSelector,
  subtaskTemplateFilesBufferSelector,
} from 'store/dashboard';
// redux
import * as Actions from 'store/inbound';
// utils
import Api from 'utils/api';
import { v4 as uuidv4 } from 'uuid';
// constants
import {
  IEnsureCreateProjectFormWorkOrderPayload,
  IEnsureDeleteWorkOrdersPayload,
  IEnsureDivideTasksPayload,
  IEnsureWorkOrdersPayload,
  ISubtaskDraftFilesBuffer,
} from 'types';

export function* ensureWorkOrders({
  payload,
}: {
  payload?: IEnsureWorkOrdersPayload;
  type: typeof Actions.workOrders.TRIGGER;
}) {
  try {
    const currentLocationId = yield select(currentLocationSelector);
    const params = {
      sort: '-createdAt',
      ...payload,
      locationId: currentLocationId,
    };
    const data = yield call(Api.inbound.getWorkOrders, { params });
    yield put(Actions.workOrders.success(data));
  } catch (err) {
    yield put(Actions.workOrders.failure(err));
  }
}

export function* ensureWorkOrder({
  payload,
}: {
  payload: string;
  type: typeof Actions.workOrder.TRIGGER;
}) {
  try {
    const data = yield call(Api.inbound.getWorkOrder, payload);
    const newData = {
      ...data,
      tasks: data.tasks.map(item => ({ ...item, mapId: uuidv4() })),
    };
    yield put(Actions.workOrder.success(newData));
  } catch (err) {
    yield put(Actions.workOrder.failure(err));
  }
}

export function* ensureDivideTasks({
  payload,
}: {
  payload: IEnsureDivideTasksPayload;
  type: typeof Actions.divideTasks.TRIGGER;
}) {
  try {
    const data = yield call(Api.inbound.divideTasks, payload);
    yield call(ensureWorkOrdersInfo);
    yield put(Actions.divideTasks.success(data));
  } catch (err) {
    yield put(Actions.divideTasks.failure(err));
  }
}

export function* ensureWorkOrdersInfo() {
  const isDepot = yield select(isDepotManagerSelector);
  if (isDepot) return;
  try {
    const currentLocationId = yield select(currentLocationSelector);
    const params = {
      locationId: currentLocationId,
    };

    const data = yield call(Api.inbound.getWorkOrdersInfo, {
      params,
    });
    yield put(Actions.workOrdersInfo.success(data));
  } catch (err) {
    yield put(Actions.workOrdersInfo.failure(err));
  }
}

export function* ensureCreateProjectFormWorkOrder({
  payload,
}: {
  payload: IEnsureCreateProjectFormWorkOrderPayload;
  type: typeof Actions.createProjectFormWorkOrder.TRIGGER;
}) {
  try {
    const filesTemplateBuffer = yield select(
      subtaskTemplateFilesBufferSelector
    );
    const filesBuffer: ISubtaskDraftFilesBuffer[] = yield select(
      subtaskFilesBufferSelector
    );
    const filesBufferMapped = filesBuffer.reduce(
      (acc, item) => [...acc, ...item.paths],
      []
    );
    const filesToDelete = [...filesTemplateBuffer, ...filesBufferMapped];

    if (filesToDelete.length) {
      yield call(ensureDeleteSubtaskFiles, {
        payload: filesToDelete,
        type: deleteSubtaskFiles.TRIGGER,
      });
    }

    const data = yield call(Api.inbound.createProjectFromWorkOrder, payload);
    yield call(ensureProjects, {
      payload: {
        refetch: true,
        noLoader: true,
      },
      type: projects.TRIGGER,
    });
    yield call(ensureWorkOrdersInfo);
    yield put(Actions.createProjectFormWorkOrder.success(data));
  } catch (err) {
    yield put(Actions.createProjectFormWorkOrder.failure(err));
  }
}

export function* ensureDeleteWorkOrders({
  payload,
}: {
  payload: IEnsureDeleteWorkOrdersPayload;
  type: typeof Actions.deleteWorkOrders.TRIGGER;
}) {
  try {
    yield call(Api.inbound.deleteWorkOrder, payload.id);
    yield call(ensureWorkOrders, {
      payload: payload.params,
      type: Actions.workOrders.TRIGGER,
    });
    yield call(ensureWorkOrdersInfo);
    yield put(Actions.deleteWorkOrders.success());
  } catch (err) {
    yield put(Actions.deleteWorkOrders.failure(err));
  }
}

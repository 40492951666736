import { DefaultTheme } from 'styled-components';

export interface IColors {
  blinkBg: '#e9edf4';
  mainDarkBlue: '#172B4D';
  blue: '#cbcfd9';
  fullBlack: '#000000';
  textBlack: '#051727';
  secondaryBlue: '#b0bad2';
  primary: '#F96302';
  primaryDark: '#E65B02';
  primaryLight: '#FF8A3E';
  mainSuccess: '#329B4F';
  lightYellow: '#FFEF82';
  black: '#061B23';
  danger: '#FF3939';
  darkDanger: '#e23232';
  lightDanger: '#FFE1E1';
  red: '#ff6969';
  grayDark: '#DFE1E6';
  subtaskGrayDark: '#BFC3CD';
  disabled: '#F5F5F5';
  grayLight: '#F4F5F7';
  lightBlue: '#737F94';
  white: '#FFFFFF';
  mutedInputBorderColor: '#E3E2E2';
  mutedInputTextColor: '#8A8A8A';
  waikawaGray: '#5C7092';
  caribbeanGreen: '#00D983';
  textGreyDark: '#6E6E70';
  deepBlue: '#2B44FF';
  lightDeepBlue: '#126AFC';
  lightTurquoise: '#DCE9FF';
  textGrey: '#EBEBEB';
  eucalyptus: '#27AE60';
  lightEucalyptus: '#DDFFE7';
  lightGreen: '#E7FFED';
  orange: '#EB7E30';
  lightOrange: '#FFEEE1';
  darkYellow2: '#F7BD0D';
  lightYellow2: '#FFF7E0';
  whiteYellow: '#FFF9E5';
  mutedText: '#807F7F';
  hawkesBlue: '#c8dbfc';
  solitude: '#EBEDF0';
  quartz: '#E2E2E7';
  subtaskNameGantt: '#1B1564';
  gentleGray: '#80808F';
  greenLightColor: '#34C759';
  lightText: '#B9BFC9';
  purple: '#58044F';
  lightPurple: '#EEA3E7';
  pine: '#056159';
  cruise: '#C1F6F6';
}

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: IColors;
    fonts: {
      primary: 'Inter';
    };
  }
}

const theme: DefaultTheme = {
  colors: {
    blinkBg: '#e9edf4',
    mainDarkBlue: '#172B4D',
    blue: '#cbcfd9',
    fullBlack: '#000000',
    textBlack: '#051727',
    secondaryBlue: '#b0bad2',
    primary: '#F96302',
    primaryDark: '#E65B02',
    primaryLight: '#FF8A3E',
    mainSuccess: '#329B4F',
    lightYellow: '#FFEF82',
    black: '#061B23',
    danger: '#FF3939',
    darkDanger: '#e23232',
    lightDanger: '#FFE1E1',
    red: '#ff6969',
    grayDark: '#DFE1E6',
    subtaskGrayDark: '#BFC3CD',
    disabled: '#F5F5F5',
    grayLight: '#F4F5F7',
    lightBlue: '#737F94',
    white: '#FFFFFF',
    mutedInputBorderColor: '#E3E2E2',
    mutedInputTextColor: '#8A8A8A',
    waikawaGray: '#5C7092',
    caribbeanGreen: '#00D983',
    textGreyDark: '#6E6E70',
    deepBlue: '#2B44FF',
    lightDeepBlue: '#126AFC',
    lightTurquoise: '#DCE9FF',
    textGrey: '#EBEBEB',
    eucalyptus: '#27AE60',
    lightEucalyptus: '#DDFFE7',
    lightGreen: '#E7FFED',
    orange: '#EB7E30',
    lightOrange: '#FFEEE1',
    darkYellow2: '#F7BD0D',
    lightYellow2: '#FFF7E0',
    whiteYellow: '#FFF9E5',
    mutedText: '#807F7F',
    hawkesBlue: '#c8dbfc',
    solitude: '#EBEDF0',
    quartz: '#E2E2E7',
    subtaskNameGantt: '#1B1564',
    gentleGray: '#80808F',
    greenLightColor: '#34C759',
    lightText: '#B9BFC9',
    purple: '#58044F',
    lightPurple: '#EEA3E7',
    pine: '#056159',
    cruise: '#C1F6F6',
  },
  fonts: {
    primary: 'Inter',
  },
};

export default theme;

import Tag from './Tag';
import { Wrap } from './styles';
import { ISubtaskDto } from 'types';

interface ISubtasksTagsProps {
  subtasks: ISubtaskDto[];
  toggleParent?: (visible: boolean) => void;
  projectId?: string;
  color?: string;
  notAvailableProject?: boolean;
  invoice?: string;
  taskName?: string;
  taskOrder?: string | number;
}

export const SubtasksTags = ({
  subtasks,
  toggleParent,
  projectId,
  color,
  notAvailableProject,
  invoice,
  taskName,
  taskOrder,
}: ISubtasksTagsProps) => (
  <Wrap>
    {subtasks.map(subtask => (
      <Tag
        key={subtask._id}
        subtask={subtask}
        toggleParent={toggleParent}
        notAvailableProject={notAvailableProject}
        projectId={projectId}
        color={color}
        invoice={invoice}
        taskName={taskName}
        taskOrder={taskOrder}
      />
    ))}
  </Wrap>
);

SubtasksTags.defaultProps = {
  subtasks: [],
};

import styled from 'styled-components';
import { commonInputStyles } from 'components/common/commonInputStyles';

export const DatePickerInputStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &:after {
    content: '';
    background-image: url(/assets/icons/down.svg);
    position: absolute;
    width: 7px;
    height: 4px;
    right: 10px;
  }
  & > input {
    ${commonInputStyles};
    width: 165px;
    height: 30px;
    padding-left: 38px;
    position: relative;
    outline: none;
    &:focus {
      outline: none;
    }
  }
  .calendar-icon {
    position: absolute;
    cursor: pointer;
    top: 15%;
    left: 10px;
    z-index: 1;
  }
  input[type='date']:after {
    content: attr(placeholder);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
